<p-confirmDialog></p-confirmDialog>
<div class="coordinator-view">
  <div class="inspector-video-container">
    <canvas #inspectorVideo class="inspector-canvas"></canvas>
  </div>
  <div class="coordinator-video-container" [ngStyle]="{'display': isSessionStarted ? '' : 'none'}">
    <video #coordinatorVideo autoplay class="coordinator-video"></video>
  </div>
  <div class="controls">
    <p-button *ngIf="!isSessionStarted && isMediaNetworkConnected" type="button" label="Start Session" (click)="startSession($event)" [disabled]="!isMediaNetworkConnected || isSessionStarting"></p-button>
    <p-button *ngIf="isSessionStarted && isInspectorConnected" type="button" [label]="isRecording ? 'Stop Rec' : 'Start Rec'" (click)="record($event)" [ngClass]="{'p-button-danger': isRecording, 'p-button-success': !isRecording}"></p-button>
    <p-button *ngIf="isSessionStarted && isRecording" type="button" [label]="isPaused ? 'Resume Rec' : 'Pause Rec'" (click)="pause()"></p-button>
    <p-button *ngIf="isSessionStarted && isInspectorConnected" type="button" label="Take Photo" (click)="takePhoto($event)"></p-button>
    <p-button *ngIf="isSessionStarted" type="button" label="End Session" severity="danger" (click)="endSession($event)"></p-button>
    <p-dropdown *ngIf="micDevices.length" [options]="micDevices" (onChange)="selectMic($event)" [(ngModel)]="selectedMic" name="mics" id="mics"></p-dropdown>
    <p-dropdown *ngIf="speakerDevices.length" [options]="speakerDevices" (onChange)="selectSpeaker($event)" [(ngModel)]="selectedSpeaker" name="speakers" id="speakers"></p-dropdown>
    <p-button *ngIf="isSessionStarted && isInspectorConnected" type="button" label="Get Cameras" (click)="getInspectorCameraList($event)"></p-button>
    <p-dropdown *ngIf="inspectorCameraList?.length" [options]="inspectorCameraList ? inspectorCameraList : []" [optionLabel]="'label'" [optionValue]="'deviceId'" (onChange)="selectInspectorCamera($event)" [(ngModel)]="selectedInspectorCamera" name="inspector-cameras" id="inspector-cameras"></p-dropdown>
    <p-button *ngIf="!isSessionStarted" type="button" label="Close" severity="info" (click)="endSession($event)"></p-button>
    <button pButton type="button" label="Test" (click)="test()"></button>
  </div>
</div>
