import { Component, OnInit, OnDestroy, ViewChild, ElementRef, } from '@angular/core';
import { ZoomService } from '../services/zoom.service';
import uitoolkit from '@zoom/videosdk-ui-toolkit'
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css'
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'zoom-ui',
  standalone: true,
  imports: [],
  template: `
    <div #sessionContainer id='sesionContainer'></div>
    <div #previewContainer id='previewContainer'></div>`,
  // styleUrl: './zoom.component.scss'
})
export class ZoomComponent {
  @ViewChild('sessionContainer', { static: true }) sessionContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('previewContainer', { static: true }) previewContainer!: ElementRef<HTMLDivElement>;
  sessionConfig: any;
  zoomui: any;
  constructor(private zoomService: ZoomService, private cdr: ChangeDetectorRef) {
    console.log('ZoomComponent initialized');
  }

  ngOnInit(): void {
    this.cdr.detach();
    this.sessionConfig = {
      videoSDKJWT: '',
      sessionName: 'GoVideo',
      userName: 'Coordinator',
      // sessionPasscode: 'abc123',
      features: ['video', 'audio', 'share', 'chat', 'users', 'settings']
    }
    this.zoomService.getToken(this.sessionConfig.sessionName, 'Coordinator', 1)
    .then((token) => {
      console.log('Zoom token:', token);
      this.sessionConfig.videoSDKJWT = token;
      const sessionContainer = this.sessionContainer.nativeElement;
      uitoolkit.openPreview(sessionContainer);
      uitoolkit.onSessionJoined((payload: any) => {
        console.log('Zoom session joined:', payload);
      });
      console.log('Zoom UI initialized', this.zoomui);
      this.cdr.reattach();
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy(): void {
  }
}
