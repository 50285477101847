import { ApplicationConfig, provideZoneChangeDetection, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ZoomService } from './services/zoom.service';
import { MediaService } from './services/media.service';
import { UploadService } from './services/upload.service';
import { DeviceService } from './services/device.service';
import { DownloadService } from './services/download.service';
import { NotificationService } from './services/notification.service';
import { SessionService } from './services/session.service';

import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    importProvidersFrom(BrowserAnimationsModule, BrowserModule),
    provideHttpClient(),
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService,
    ConfirmationService,
    MessageService,
    ZoomService,
    MediaService,
    UploadService,
    DeviceService,
    DownloadService,
    NotificationService,
    SessionService,
  ]
};