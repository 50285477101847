import { Injectable } from '@angular/core';
import { MessageService, Message } from 'primeng/api';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  DEFAULT_SEVERITY = 'info';
  DEFAULT_SUMMARY = 'GoVideo';
  DEFAULT_DETAIL = '';
  DEFAULT_CLOSABLE = true;
  DEFAULT_LIFE = 6000;

  constructor(private messageService: MessageService) { }

  showToast(
    severity: string = this.DEFAULT_SEVERITY,
    summary: string = this.DEFAULT_SUMMARY,
    detail: string = this.DEFAULT_DETAIL,
    life: number = this.DEFAULT_LIFE,
    closable: boolean = this.DEFAULT_CLOSABLE,
  ): void {
    const m: Message = { severity, summary, detail, sticky: !life ? true : false, life, closable };
    this.messageService.add(m);
  }

  showDebugToast(
    detail: string = this.DEFAULT_DETAIL,
    consoleMessage: string = '',
  ): void {
    // todo: uncomment for production
    // if (environment.production) {
    //     return;
    // }
    
    const m: Message = { severity: 'warn', summary: 'GoVideo Debug - Check Console', detail, sticky: true };
    this.messageService.add(m);
    console.log(consoleMessage);
  }

}
