import { Injectable, Injector } from '@angular/core';
import { ZoomService } from './zoom.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  _sessionId: string = '';
  _imageCount: number = 0;
  _zoomSesssionId: string = '';
  private zoomService!: ZoomService;
  
  constructor(
    private injector: Injector,
  ) {
      setTimeout(() => {
        this.zoomService = this.injector.get(ZoomService);
      });
   }
  
  get sessionId(): string {
    return this._sessionId;
  }

  set sessionId(value: string) {
    this._sessionId = value;
  }

  get imageCount(): number {
    return this._imageCount;
  }

  set imageCount(value: number) {
    this._imageCount = value;
  }

  get zoomSessionId(): string {
    return this._zoomSesssionId;
  }

  set zoomSessionId(value: string) {
    this._zoomSesssionId = value;
  }

  incrementImageCount() {
    this._imageCount++;
  }

  decrementImageCount() {
    if (this._imageCount > 0) {
      this._imageCount--;
    }
  }

  resetImageCount() {
    this._imageCount = 0;
  }

  async getSummary(): Promise<any|void> {
    const zoomSession = await  await this.zoomService.apiZoomSessionDetails(this._zoomSesssionId);
    const sessionSummary = {
      sessionId: this._sessionId,
      mediaCount: this._imageCount,
      zoomSessionId: this._zoomSesssionId,
      hasRecording: zoomSession.has_recording,
      duration: zoomSession.duration,
    };
    return sessionSummary;
  }
}
