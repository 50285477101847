import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface IDownloadUrl {
    url: string;
    key: string;
    size: number;
    type: string;
};

@Injectable({
    providedIn: 'root'
})
export class DownloadService {
    private apiUrl = `${environment.apiUrl}/media`;

    constructor(private http: HttpClient) { }

    async getOjectDetails(key: string): Promise<IDownloadUrl|void> {
        try {
            const downloadApi = `${this.apiUrl}/download-url?key=${key}`;
            const response = await this.http.get<IDownloadUrl>(downloadApi).toPromise();
            if (response) {
            return response;
            }
        } catch (error) {
            console.error('Error fetching object details:', error);
        }
        return;
    }
}
