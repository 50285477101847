import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import ZoomVideo,
{
  VideoQuality,
  ParticipantPropertiesPayload,
  Participant,
  CommandChannel,
  ExecutedFailure,
  CommandChannelMsg
} from '@zoom/videosdk';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { NotificationService } from './notification.service';
import { MediaService } from './media.service';
import { SessionService } from './session.service';
import { Router } from '@angular/router';

export type CommandChannelResponse = CommandChannelMsg;
export type ZoomClientType = ReturnType<typeof ZoomVideo.createClient>;

export enum CommandEventType {
  IMAGE_CAPTURE_REQUESTED = 'Image Capture Requested',
  IMAGE_CAPTURE_ACKNOWLEDGE = 'Image Capture Acknowledge',
  IMAGE_CAPTURE_COMPLETED = 'Image Capture Completed',
  IMAGE_UPLOAD_COMPLETED = 'Image Upload Completed',
  CAMERA_GET_LIST_REQUESTED = 'Camera Get List Requested',
  CAMERA_GET_LIST_COMPLETED = 'Camera Get List Completed',
  CAMERA_SWITCH_REQUESTED = 'Camera Switch Requested',
  CAMERA_SWITCH_COMPLETED = 'Camera Switch Completed',
  CHAT_SEND_MESSAGE = 'Chat Send Message',
  CHAT_RECEIVE_MESSAGE = 'Chat Receive Message',
  VIDEO_RECORDING_STARTED = 'Video Recording Started',
  VIDEO_RECORDING_STOPPED = 'Video Recording Stopped',
  LOCATION_INSPECTOR_CHECK_IN = 'Location Inspector Check-In',
  COORDINATOR_TEST = 'Coordinator Test',
  SESSION_END_REQUESTED = 'Session End Requested',
}

export interface CommandEvent {
  type: CommandEventType;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class ZoomService {
  private static _client: ReturnType<typeof ZoomVideo.createClient> | null;
  private apiUrl = `${environment.apiUrl}`;
  public static videoQuality1080P: VideoQuality = VideoQuality.Video_1080P;
  private static _participantIdentity: Participant | null = null;
  private _inspectorCameraList: any[] | null = null;
  private mediaService: MediaService = {} as MediaService;
  private _zoomSesssionId: string | null = null;
  private sessionService!: SessionService;

  constructor(
    private http: HttpClient,
    private notify: NotificationService,
    private injector: Injector,
    private router: Router,
  ) {
      const mediaCompatibilty = ZoomVideo.checkSystemRequirements();
      console.log('Media Compatibility:', mediaCompatibilty);
      if (!ZoomService._client) {
        ZoomService._client = ZoomVideo.createClient();
        console.log(`ZoomVideo Client v${ZoomVideo.VERSION} initialized`);
      }
      
      setTimeout(() => {
        this.mediaService = this.injector.get(MediaService);
        this.sessionService = this.injector.get(SessionService);
      });
      
  }

  get client() {
    return ZoomService._client;
  }

  get participantIdentity(): Participant | null {
    return ZoomService._participantIdentity;
  }

  get inspector(): Participant | null {
    if (!ZoomService._client) {
      throw new Error('Zoom client not initialized');
    }
    return ZoomService._client?.getAllUser().find((participant) => participant.userIdentity?.toLowerCase() === 'inspector') || null;
  }

  get coordinator(): Participant | null {
    if (!ZoomService._client) {
      throw new Error('Zoom client not initialized');
    }
    return ZoomService._client.getAllUser().find((participant) => participant.userIdentity?.toLowerCase() === 'coordinator') || null;
  }

  get inspectorCameraList(): any[] | null {
    return this._inspectorCameraList;
  }

  set inspectorCameraList(cameraList: any[] | null) {
    this._inspectorCameraList = cameraList;
  }

  get zoomSessionId(): string | null {
    return this._zoomSesssionId;
  }

  async connect(topic: string, userName: string, role: number = 1): Promise<ZoomClientType | void> {
    try {
      if (!ZoomService._client) {
        throw new Error('Zoom client not initialized');
      }
      const response: any = await firstValueFrom(this.http.post(`${this.apiUrl}/govideo/token`, {
        sessionName: topic,
        role,
        sessionId: topic,
        userIdentity: userName,
      }));
      if (!response.token || !response.token.length) {
        throw new Error('Error getting zoom token');
      }
      await ZoomService._client.init('en-US', 'Global', { patchJsMedia: true, enforceMultipleVideos: true });
      await ZoomService._client.join(topic, response.token, userName, '', 10);
      this._zoomSesssionId = ZoomService._client.getSessionInfo().sessionId;
      this.notify.showDebugToast(`Zoom Session ID: ${this._zoomSesssionId}`, JSON.stringify(ZoomService._client.getSessionInfo(), null, 2));
      ZoomService._participantIdentity = ZoomService._client.getCurrentUserInfo();
      this.sessionService.zoomSessionId = this._zoomSesssionId;
      this.sessionService.sessionId = topic;
      this.attachEventListeners();
      return ZoomService._client;
    } catch (error) {
      console.error('Error initializing Zoom client:', error);
    }
  }

  attachEventListeners(): void {
    ZoomService._client?.on('command-channel-message', async (payload: CommandChannelMsg) => {
      // this.notify.showDebugToast(`GoVideo Channel Message Sent`, payload?.text);
      console.log('Received GoVideo Channel Message:', payload);
      await this.handleCommandEvents(payload);
    });
  }

  async handleCommandEvents(payload: CommandChannelMsg): Promise<void> {
    const event = JSON.parse(payload.text);
    const receiver = (payload.senderId === this.coordinator?.userId) ? 'inspector' : 'coordinator';
    switch (event.type) {
      case CommandEventType.IMAGE_CAPTURE_REQUESTED:
        this.notify.showToast('info', 'GoVideo', 'Coordinator requested image');
        const commandResponse = await this.sendCommand({
          type: CommandEventType.IMAGE_CAPTURE_ACKNOWLEDGE,
          data: {},
        }, this.coordinator?.userId || 0);
        const mediaFile = await this.mediaService.capturePhoto(this.sessionService.sessionId);
        if (mediaFile) {
          this.sessionService.incrementImageCount();
          const commandResponse = await this.sendCommand({
            type: CommandEventType.IMAGE_CAPTURE_COMPLETED,
            data: { imageUrl: mediaFile.url  }, 
          }, this.coordinator?.userId || 0);
        }
        break;
      case CommandEventType.IMAGE_CAPTURE_ACKNOWLEDGE:
        this.notify.showToast('info', 'GoVideo', 'Image capture acknowledged');
        break;
      case CommandEventType.IMAGE_CAPTURE_COMPLETED:
        this.sessionService.incrementImageCount();
        this.notify.showToast('info', 'GoVideo', 'Image capture completed');
        break;
      case CommandEventType.IMAGE_UPLOAD_COMPLETED:
        this.notify.showToast('info', 'GoVideo', 'Image uploaded successfully');
        break;
      case CommandEventType.CAMERA_GET_LIST_REQUESTED:
        this.notify.showToast('info', 'GoVideo', 'Inspector requested camera switch');
        try {
          const cameras = ZoomService._client
            ?.getMediaStream()
            .getCameraList();
          const CommandChannelResponse = await this.sendCommand({ type: CommandEventType.CAMERA_GET_LIST_COMPLETED, data: { cameras }}, this.coordinator?.userId || 0);
        } catch (error) {
          console.log(error);
        }
        break;
      case CommandEventType.CAMERA_GET_LIST_COMPLETED:
        this._inspectorCameraList = event.data.cameras;
        break;
      case CommandEventType.CAMERA_SWITCH_REQUESTED:
        await ZoomService._client?.getMediaStream().switchCamera(event.data.cameraId);
        this.notify.showToast('info', 'GoVideo', 'Camera has has been switched');
        const CommandChannelResponse = await this.sendCommand({ type: CommandEventType.CAMERA_SWITCH_COMPLETED, data: { cameraId: event.data.cameraId }}, this.coordinator?.userId || 0);
        break;
      case CommandEventType.CAMERA_SWITCH_COMPLETED:
        this.notify.showToast('success', 'GoVideo', 'Inspector camera switched');
        console.log(event.data.cameraId);
      break;
      case CommandEventType.CHAT_SEND_MESSAGE:
        this.notify.showToast('info', 'GoVideo', `Chat: ${event.data.message}`);
        break;
      case CommandEventType.CHAT_RECEIVE_MESSAGE:
        this.notify.showToast('info', 'GoVideo', `Chat: ${event.data.message}`);
        break;
      case CommandEventType.VIDEO_RECORDING_STARTED:
        this.notify.showToast('info', 'GoVideo', 'Video recording started');
        break;
      case CommandEventType.VIDEO_RECORDING_STOPPED:
        this.notify.showToast('info', 'GoVideo', 'Video recording stopped');
        break;
      case CommandEventType.LOCATION_INSPECTOR_CHECK_IN:
        this.notify.showToast('info', 'GoVideo', 'Location inspector checked-in');
        break;
      case CommandEventType.SESSION_END_REQUESTED:
        this.notify.showToast('info', 'GoVideo', 'Coordinator is ending the session');
        this.leave();
        this.notify.showToast('info', 'GoVideo', 'You have left the session');  
        setTimeout(async () => {this.router.navigate(['/session-end']);}, 5000);
        break;
      case CommandEventType.COORDINATOR_TEST:
        this.notify.showToast('info', 'GoVideo', 'Coordinator initiated test');
        break;
      default:
        console.log(`Unhandled command event: ${event.type}`);
        break;
    }
  }
        
  getParticipants(): Participant[] {
    return ZoomService._client ? ZoomService._client.getAllUser() : [];
  }

  on(event: string, callback: (payload: any) => void): void {
    ZoomService._client?.on(event, callback);
  }

  async leave(): Promise<void> {
    try {
      if (!ZoomService._client) {
        throw new Error('Zoom client not initialized');
      }
      const stream = ZoomService._client.getMediaStream();
      if (stream) {
        await stream.stopAudio();
        await stream.stopVideo();
      }
      const endSession = ZoomService._participantIdentity ? ZoomService._participantIdentity.isHost : false;
      const result = await ZoomService._client.leave(endSession);
      this.cleanup();
      console.log('Left the session successfully');
    } catch (error) {
      console.error('Error leaving and ending the session:', error);
    }
  }

  destroyClient(): void {
    // ZoomVideo.destroyClient();
    // ZoomService._client = null;
  }


  cleanup(): void {
    // Add cleanup logic here
    return;
  }

  async getToken(topic: string, userName: string, role: number = 1): Promise<string | void> {
    try {
      const response: any = await firstValueFrom(this.http.post(`${this.apiUrl}/govideo/token`, {
        sessionName: topic,
        role,
        sessionId: topic,
        userIdentity: userName
      }));
      if (!response.token || !response.token.length) {
        throw new Error('Error getting zoom token');
      }
      return response.token;
    } catch (error) {
      console.error(error);
    }
  }

  async getMicList(): Promise<any[]> {
    return ZoomService._client?.getMediaStream().getMicList() || [];
  }

  async selectMic(deviceId: string): Promise<void> {
    await ZoomService._client?.getMediaStream().switchMicrophone(deviceId) || Promise.resolve();
  }

  async getSpeakerList(): Promise<any[]> {
    return ZoomService._client?.getMediaStream().getSpeakerList() || [];
  }

  async selectSpeaker(deviceId: string): Promise<void> {
    await ZoomService._client?.getMediaStream().switchSpeaker(deviceId) || Promise.resolve();
  }

  async sendCommand(commandEvent: CommandEvent, userId: number): Promise<ExecutedFailure | CommandChannelMsg> {
    const response = await ZoomService._client
      ?.getCommandClient()
      ?.send(JSON.stringify(commandEvent), userId);
    if (response && typeof response === 'object' && 'type' in response && 'reason' in response) {
      console.error('Error sending command:', response.reason);
      throw new Error(response.reason);
    }
    console.debug('Command sent successfully:', response);
    return response as CommandChannelMsg;
  }

  async apiZoomSessionDetails(zoomSessionId: string): Promise<any> {
    try {
      const response = await firstValueFrom(
        this.http.get(`${this.apiUrl}/zoom/${encodeURIComponent(encodeURIComponent(zoomSessionId))}`)
      );
      console.log('Zoom session retrieved:', response);
      return response;
    } catch (error) {
      console.error('Error retrieving Zoom session:', error);
      throw error;
    }
  }
}