import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private jwtHelper: JwtHelperService, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    let token = route.queryParams['token'] || route.params['token'];
    if (token) {
      localStorage.setItem('token', token);
    } else {
      token = localStorage.getItem('token');
    }

    let isTokenExpired: any;
    try {
      isTokenExpired = this.jwtHelper.isTokenExpired(token);
    } catch (error) {
      isTokenExpired = true;
      console.error('Invalid JWT token');
    }
    
    if (token && !isTokenExpired) {      
      let decodedToken: { role: number, sessionId: string, userIdentity: string  } | null = null;

      try {
        decodedToken = this.jwtHelper.decodeToken(token);
      } catch (error) {
        console.error('Invalid JWT token');
      }
      if (decodedToken) {
        const { role, sessionId, userIdentity  } = decodedToken;
        if (typeof role === 'number' && sessionId && userIdentity) {
          const urlSegments = route.url;
          const userIdentityFromUrl = urlSegments.length > 0 ? urlSegments[0].path : null;
          if (userIdentityFromUrl === userIdentity && sessionId === route.paramMap.get('sessionId')) {
            return true;
          } else {
            localStorage.removeItem('token');
            return this.router.parseUrl('/contact-us');
          }
        }
  
      }

    }

    localStorage.removeItem('token');
    return this.router.parseUrl('/contact-us');
  }
}