<div class="flex flex-column align-content-center align-items-center min-w-screen min-h-screen">
  <p-card class="flex flex-column align-content-center align-items-center gap-2 border-round shadow-4 w-8">
    <ng-template pTemplate="header">
      <div class="pt-5 pb-0">
        <div class="text-center text-4xl font-bold">
          GoVideo
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="content">
      <div class="px-4">
        <div>
          <hr>
        </div>
        <div class="text-center text-4xl tes">
          <p>It looks like you were trying to join a session without valid credentials or
          you have reached a page that does not exist</p>
        </div>
        <div class="text-center text-2xl">
          <p class="font-italic">Please contact Aspen Field Services for assistance</p>
        </div>
        <div>
          <hr>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <div class="">
        <div class="justify-content-right">
          <div class="px-4 text-right w-full font-semibold px-2">v3.0</div>
        </div>
      </div>
    </ng-template>
  </p-card>

</div>