<p-toast #appToast/>
<p-confirmDialog
    #appConfirm
    key="positionDialog" 
    position="center" 
    rejectButtonStyleClass="p-button-outlined" />
<p-messages #appMessage
    [enableService]="false" 
    [closable]="false" />
<router-outlet />
