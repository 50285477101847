import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from'../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) { }

  generateToken(sessionId: string, role: string, userIdentity: string): Promise<any> {
    return firstValueFrom (this.http.post<{ jwt: string }>(`${environment.apiUrl}/sessions/generate`, { sessionId, role, userIdentity }) );
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return token ? !this.jwtHelper.isTokenExpired(token) : false;
  }

  async login(sessionId: string, role: string, userIdentity: string): Promise<void> {
    const response = await this.generateToken(sessionId, role, userIdentity);
    if (!response.jwt) {
      return;
    }
    localStorage.setItem('token', response.jwt);
    this.router.navigate([`/${role}`]);
  }

  logout() {
    // localStorage.removeItem('token');
    localStorage.clear();
    // this.router.navigate(['/login']);
  }

  authorize(sessionId: string): {sessionId: string; role: number, userIdentity: string } | void{
    const token = localStorage.getItem('token');
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      const decodedToken = this.jwtHelper.decodeToken(token);
      const tokenSessionId = decodedToken.sessionId;
      if (sessionId === tokenSessionId) {
        return decodedToken;
      }
    }
  }
}