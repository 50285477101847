import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export interface IUploadUrl {
  url: string;
  key: string;
};

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private apiUrl = `${environment.apiUrl}/media`;
  
  constructor(private http: HttpClient) { }

  async getSignedUrl(filename: string, sessionId: string): Promise<IUploadUrl|void> {
    try {
      const api = `${this.apiUrl}/upload-url?key=${filename}&sessionId=${sessionId}`
      const uploadUrl: any = await this.http.get(api).toPromise();
      if (uploadUrl) {
        return uploadUrl;  
      }    
    } catch (error) {
      console.error('Error generating signed URL', error);
      return; 
    }
  }

  async uploadPhoto(filename: string, sessionId: string, photo: Blob): Promise<any> {
    let uploadUrl: IUploadUrl | void = await this.getSignedUrl(filename, sessionId);
    if (uploadUrl) {
      const response = await fetch(uploadUrl.url, {
        method: "PUT",
        body: photo
      });
      if (response.status === 200) {
        return { url: response.url, key: uploadUrl.key } as any;
      }
    }
    return;
  }
  
  
}
