import { Component, OnInit, Injector } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-session-end',
  standalone: true,
  imports: [],
  templateUrl: './session-end.component.html',
  styleUrl: './session-end.component.scss'
})
export class SessionEndComponent implements OnInit  {
  sessionSummary: any;
  sessionService!: SessionService

  constructor(private authService: AuthService, private injector: Injector) {
    setTimeout(() => {
      this.sessionService = this.injector.get(SessionService);
    });
  }

  async ngOnInit() {
    try {
      this.authService.logout();
      // todo: if the coordinator, end the session. If the inspector, leave the session.

      // todo: get session details to show
      this.sessionSummary = await this.sessionService.getSummary();
      console.log('Session Summary:', this.sessionSummary);
    } catch (error) {
      console.log('Error during session initialization:', error);
    }
  }

  endSession() {
    this.authService.logout();
  }
}
