import { Injectable, Injector } from '@angular/core';
import { DeviceService } from './device.service';
import { UploadService } from './upload.service';
import { ZoomService } from './zoom.service';
import { CommandEventType, CommandEvent, CommandChannelResponse } from './zoom.service';
import { Participant } from '@zoom/videosdk';
import { IUploadUrl } from './upload.service';
import { DownloadService, IDownloadUrl } from './download.service';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SessionService } from './session.service';

interface ImageSettingsInterface {
  quality: number,
  height: number,
  width: number,
  thumbnailHeight: number
}

interface ICaptureIspectorImageResponse {
  blob: Blob,
  imageObjectUrl: URL
}

@Injectable({ 
  providedIn: 'root' 
})
export class MediaService {
  private _coordinatorVideoElement!: HTMLVideoElement;
  private _inspectorVideoElement!: HTMLVideoElement;
  private IMAGE_SETTINGS!: ImageSettingsInterface;
  private THUMBNAIL_SETTINGS!: ImageSettingsInterface;
  private zoomService!: ZoomService;
  private uploadService!: UploadService;
  private downloadService!: DownloadService;

  apiUrl = `${environment.apiUrl}/media`;

  constructor(
    private deviceService: DeviceService,
    private injector: Injector,
    private http: HttpClient,
    private sessionService: SessionService,
  ) { 
    
    setTimeout(() => {
      this.zoomService = this.injector.get(ZoomService);
      this.uploadService = this.injector.get(UploadService);
      this.downloadService = this.injector.get(DownloadService);
    });

    this.IMAGE_SETTINGS = {
      quality: 0.85,
      height: this.imageHeight(),
      width: this.imageWidth(),
      thumbnailHeight: 78
    }
    
    this.THUMBNAIL_SETTINGS = {
      quality: 0.5,
      height: 96,
      width: 128,
      thumbnailHeight: 96
    }
  }

  get coordinatorVideoElement(): HTMLVideoElement | null {
    return this._coordinatorVideoElement;
  }

  set coordinatorVideoElement(videoElement: HTMLVideoElement) {
    this._coordinatorVideoElement = videoElement;
  }

  set inspectorVideoElement(videoElement: HTMLVideoElement) {
    this._inspectorVideoElement = videoElement;
  }

  get inspectorVideoElement(): HTMLVideoElement | null {
    return this._inspectorVideoElement;
  }

  getImageFilename(sessionId: string): string {
    const timestamp = new Date().getTime();
    return `gv_photo_${sessionId}_${timestamp}.jpg`;
  }
  async capturePhoto(sessionId: string): Promise<any|void> {
    try {
      if (!this._inspectorVideoElement) {
        console.error('No inspector video element found');
        return;
      }
      const video = this._inspectorVideoElement;
      const imageFilename = this.getImageFilename(sessionId);
      const coordinator = this.zoomService.client?.getAllUser().find((p) => p.userIdentity === 'coordinator') as Participant;
      if (!coordinator) {
        console.error('No coordinator participant found');
        return;
      }

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const contentType = 'image/jpeg';
        
      // adjust dimenstions based on orientation
      let height = (video.videoWidth >= video.videoHeight) ? this.IMAGE_SETTINGS.height : this.IMAGE_SETTINGS.width;
      let width = (video.videoWidth >= video.videoHeight) ? this.IMAGE_SETTINGS.width : this.IMAGE_SETTINGS.height;
    
      // preserve aspect ratio and avoid upscaling / supersampling
      height = Math.min(height, video.videoHeight);
      width = Math.min(width, video.videoWidth);

      canvas.height = height;
      canvas.width = width;
      ctx?.drawImage(video, 0, 0, width, height);
      
      const image: ICaptureIspectorImageResponse = await new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          try {
            if (blob) {
              const imageObjectUrl = new URL(URL.createObjectURL(blob)); 
              resolve({ imageObjectUrl, blob } as ICaptureIspectorImageResponse);
            } else {
              console.error("Capture inspector image failed to convert canvas to blob");
              reject();
            }
          } catch (error: any) {
            console.error("Capture inspector image failed to convert canvas to blob", error);
            reject();
          }
        }, contentType, this.IMAGE_SETTINGS.quality);
      });
  
      if (image) {
        const response = await this.uploadService.uploadPhoto(imageFilename, sessionId, image.blob);
        if (response) {
          const mediaFile = await this.saveMedia(response.key, sessionId);
          if (mediaFile) { 
            return mediaFile;
          }
        }
      } else {
        return;
      }
          
    } catch (error: any) {
      console.error('Error capturing inspector image', error);
      throw new Error('Error capturing inspector image from canvas to blob', error);
    }
  }

  imageHeight() : number {
      return this.deviceService.isApplePhone() ? 1080 : 1440;
  }

  imageWidth() : number {
      return this.deviceService.isApplePhone() ? 1440 : 1920;
  }

  async saveMedia(key: string, sessionId: string): Promise<any> {
    try {
      let object: IDownloadUrl | void = await this.downloadService.getOjectDetails(key);
      if (object) {
        const response = await firstValueFrom(
          this.http.post(`${this.apiUrl}`, {
            sessionId,
            name: object.key.split('/').pop(),
            key,
            type: object.type,
            size: object.size,
            url: object.url
          })
        );
        if (response) {
          return response;
        }
      }
    } catch (error: any) {
      console.error('Error saving media', error);
      throw new Error('Error saving media', error);
    }
    return;
  }
}
