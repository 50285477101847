import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ZoomClientType, ZoomService } from '../services/zoom.service';
import { ChangeDetectorRef } from '@angular/core';
import { ParticipantPropertiesPayload, VideoQuality } from '@zoom/videosdk';
import { ButtonModule } from 'primeng/button';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmationService, MessageService, Message } from 'primeng/api';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AuthService } from '../services/auth.service';
import { CommandEvent, CommandEventType } from '../services/zoom.service';
import { MediaService } from '../services/media.service';
import { DeviceService } from '../services/device.service';
import { SessionService } from '../services/session.service';
import { NotificationService } from '../services/notification.service'; // Import the new notification service

interface ImageSettingsInterface {
  quality: number,
  height: number,
  width: number,
  thumbnailHeight: number
}

@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.scss'],

  standalone: true,
  imports: [CommonModule, ButtonModule, ConfirmDialogModule],
})
export class InspectorComponent implements OnInit, OnDestroy {
  @ViewChild('inspectorVideo', { static: true }) inspectorVideo!: ElementRef<HTMLVideoElement>;
  sessionId: string = "";
  zoomClient: ZoomClientType | void | null = null;
  videoQuality = VideoQuality.Video_1080P;
  sessionEnded = false;

  constructor(
    private route: ActivatedRoute,
    private zoomService: ZoomService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthService,
    private mediaService: MediaService,
    private deviceService: DeviceService,
    private sessionService: SessionService,
    private notify: NotificationService,
  ) { }

  async test() {
    if (!this.zoomService.inspector || !this.zoomService.coordinator) {
      throw new Error('Inspector or coordinator not found');
    }
    // this.micDevices = await this.zoomService.getMicList();
    const commandEvent: CommandEvent = {
      type: CommandEventType.COORDINATOR_TEST,
      data: null,
    }   
    const commandResponse = await this.zoomService.sendCommand(commandEvent, this.zoomService.coordinator.userId);
    console.log(commandResponse);
  }

  async ngOnInit(): Promise<void> {
    this.sessionId = this.route.snapshot.paramMap.get('sessionId') || '';
    this.notify.showToast('info', 'GoVideo', 'Connecting to media network...');
    if (this.sessionId) {
      const authorized = this.authService.authorize(this.sessionId);
      if (authorized && typeof authorized.role === 'number' && authorized.userIdentity && authorized.sessionId) {
        this.zoomClient = await this.zoomService.connect(authorized.sessionId, authorized.userIdentity, authorized.role);
        if (!this.zoomClient) {
          this.notify.showToast('error', 'GoVideo', 'Failed to connect to media network.', 0);
          this.router.navigate(['/contact-us']);
        } else {
          this.setupEventListeners();
          this.notify.showToast('success', 'GoVideo', 'Connected to media network.');
        }
      } else {
        this.notify.showToast('error', 'GoVideo', 'Not authorized to access this session.', 0);
        this.router.navigate(['/contact-us']);
      }
    } else {
      this.router.navigate(['/contact-us']);
    }
  }

  ngOnDestroy(): void {
    if (!this.sessionEnded) {
      const user = this.zoomClient?.getCurrentUserInfo() || null;
      this.zoomService.leave();
      this.zoomService.destroyClient();
    }
  }

  // async initZoom() {
  //   const userName = 'Inspector';
  //   this.cdr.detach();
  //   this.zoomClient = await this.zoomService.connect(this.sessionId, userName, 1);
  //   this.cdr.reattach();
  //   this.cdr.detectChanges();
  // }

  setupEventListeners() {
    if (!this.zoomService.client) {
      throw new Error('Zoom client not initialized');
    }
    this.zoomService.client.on('peer-video-state-change', (payload: { action: "Start" | "Stop"; userId: number }) => {
      console.log('Peer video state change');
      const participant = this.zoomService?.client?.getUser(payload.userId);
      console.log("Participant:", participant);
      if (payload.action === 'Start') {
        console.log('Peer state change event - Start video');
        if (participant?.userIdentity === "coordinator") {
          // this.cdr.detach();
          // this.startCoordinatorVideo(participant);
          // this.cdr.reattach();
          // this.cdr.detectChanges();
        }
      } else {
        console.log('Peer state change event - Stop video');
        this.notify.showToast('info', 'GoVideo', 'Coordinator has stopped the video');
      }
    });

    this.zoomService.client.on('user-added', (payload: ParticipantPropertiesPayload) => {
      this.notify.showToast('info', 'GoVideo', 'Coordinator has joined the session');
      console.log('Participant joined event :', payload);
    });

    this.zoomService.client.on('user-removed', (payload: ParticipantPropertiesPayload) => {
      this.notify.showToast('info', 'GoVideo', 'Coordinator has left the session');
      console.log('Participant left event:', payload);
    });

    this.zoomService.client.on('session-ended', () => {
      this.sessionEnded = true;
      this.notify.showToast('warn', 'GoVideo', 'Session has ended');
      console.log('Session ended');
    });

    this.zoomService.client.on('error', (error: Error) => {
      console.error('Zoom client error:', error);
      this.notify.showToast('error', 'GoVideo', 'An error occurred while connecting to the media network.', 0);
    });

    this.zoomService.client.on('zoom-sdk-ready', () => {
      console.log('Zoom SDK ready');
      // this.startVideo();
    });



  }

  async startVideo(event: any): Promise<void> {
    try {
      if (!this.zoomClient) {
        throw new Error('Zoom client not initialized');
      }

      const stream = this.zoomClient.getMediaStream();
      if (!stream) {
        throw new Error('Media stream not found');
      }

      const inspectorVideo = this.inspectorVideo.nativeElement;
      this.mediaService.inspectorVideoElement = inspectorVideo;

      inspectorVideo.width = 1024;
      inspectorVideo.height = 768;

      if (!inspectorVideo) {
        throw new Error('Video elements not found');
      }
      this.cdr.detach();

      const cameras = stream.getCameraList();
      const cameraId = cameras[0]?.deviceId; // Use the appropriate camera ID

      // Apply constraints for higher resolution
      const constraints = {
          width: 1280,  // Desired width
          height: 720,  // Desired height
          frameRate: 30 // Optional: Desired frame rate
      };

      // Start the video with the selected camera and constraints
      // await stream.startVideo({ 
      //     cameraId: cameraId, 
      //     captureWidth: constraints.width, 
      //     captureHeight: constraints.height,
      //     fps: constraints.frameRate 
      // });

      await stream.startAudio();
      await stream.startVideo({
        videoElement: inspectorVideo,
        fullHd: true,
        cameraId: this.deviceService.isMobile() ? 'environment' : cameraId, 
        captureWidth: constraints.width, 
        captureHeight: constraints.height,
        fps: constraints.frameRate 
      })
      // await stream.renderVideo( inspectorVideo, this.zoomClient.getCurrentUserInfo().userId, 640, 360, 0, 0, this.videoQuality);  
      console.log('Inspector video and audio started');
    } catch (error: any) {
      if (error.type !== 'INVALID_OPERATION' || error.reason !== 'Video is started') {
        console.error('Error starting inspector video and audio', error);
      } else {
        console.log('Inspector video is already started');
      }
    } finally {
      this.cdr.reattach();
      this.cdr.detectChanges();
    }
  }

  async endSession() {
    this.cdr.detach();
    const user = this.zoomClient?.getCurrentUserInfo() || null;
    await this.zoomService.leave();
    this.zoomService.destroyClient();
    this.sessionEnded = false;
    this.cdr.reattach();
    console.log('Inspector session ended');
    this.router.navigate(['/session-end']);
  }

  capturePhoto() {
    const video = this.inspectorVideo.nativeElement;
    if (video) {
      // const stream = video.captureStream();
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d')?.drawImage(video, 0, 0);
      const img = new Image();
      img.src = canvas.toDataURL('image/png');
      this.notify.showToast('info', 'GoVideo', 'Photo captured');
    }
  }

  public createImageFromCanvas(video: HTMLVideoElement,
    imagesDiv: HTMLDivElement,
    imageId: string,
    settings: ImageSettingsInterface): Promise<Blob | void> {

    try {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const contentType = 'image/jpeg';

      // adjust dimenstions based on orientation
      let height = (video.videoWidth >= video.videoHeight) ? settings.height : settings.width;
      let width = (video.videoWidth >= video.videoHeight) ? settings.width : settings.height;

      // preserve aspect ratio and avoid upscaling / supersampling
      height = Math.min(height, video.videoHeight);
      width = Math.min(width, video.videoWidth);

      canvas.height = height;
      canvas.width = width;
      const canvasStats = {
        'canvasHeight': canvas.height,
        'canvasWidth': canvas.width
      };
      console.log(canvasStats);
      ctx?.drawImage(video, 0, 0, width, height);
      return new Promise((resolve, reject) => {
        // this.logger.trace(`Converting canvas to blob in createImageFromCanvas() / return new Promise().`);
        canvas.toBlob((blob) => {
          if (blob === null) {
            console.log("Failed to convert canvas to blob");
            reject();
          }
          // const imageUrl = URL.createObjectURL(blob);
          // this.appendImage(imagesDiv, imageId, imageUrl, settings.thumbnailHeight);
          // this.logger.trace(`Successfully converted canvas to blob in createImageFromCanvas() / resolve(blob).`)
          if (blob) {
            resolve(blob);
          } else {
            reject();
          }
        }, contentType, settings.quality);
      });
    } catch (e: any) {
      // this.logger.error(`Exception caught in createImageFromCanvas()`, { errorMessage: e.message, error: e });
      return Promise.reject();
    }
  }

}