import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  isApplePhone(): boolean {
    return navigator?.userAgent && navigator.userAgent.includes('iPhone') ? true : false;
  }

  isMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  async getVideoDevices() {
    const videoDEvices = await  navigator.mediaDevices.enumerateDevices()
      .then(devices => devices.filter(device => device.kind === 'videoinput'));
  }

}
